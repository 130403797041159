
/**
 * Ion Driver (https://www.ennapd.com/ionDriver)
 *
 * Copyright © 2019-present Enappd. All rights reserved.
 *
 * This source code is licensed as per the terms found in the
 * LICENSE.md file in the root directory of this source tree.
 */


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'slides',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './home/home.module#HomePageModule'
  },
  { path: 'wallet', loadChildren: './wallet/wallet.module#WalletPageModule' },
  { path: 'paymentmethod', loadChildren: './paymentmethod/paymentmethod.module#PaymentmethodPageModule' },
  { path: 'profile', loadChildren: './profile/profile.module#ProfilePageModule' },
  { path: 'paymentmethod', loadChildren: './paymentmethod/paymentmethod.module#PaymentmethodPageModule' },
  { path: 'history', loadChildren: './history/history.module#HistoryPageModule' },
  { path: 'carddetail', loadChildren: './carddetail/carddetail.module#CarddetailPageModule' },
  { path: 'invite', loadChildren: './invite/invite.module#InvitePageModule' },
  { path: 'invitefriends', loadChildren: './invitefriends/invitefriends.module#InvitefriendsPageModule' },
  { path: 'vehiclemanagement', loadChildren: './vehiclemanagement/vehiclemanagement.module#VehiclemanagementPageModule' },
  { path: 'addnewvehicle', loadChildren: './addnewvehicle/addnewvehicle.module#AddnewvehiclePageModule' },
  { path: 'vehiclemanagement', loadChildren: './vehiclemanagement/vehiclemanagement.module#VehiclemanagementPageModule' },
  { path: 'notifications', loadChildren: './notifications/notifications.module#NotificationsPageModule' },
  { path: 'customer-detail', loadChildren: './customer-detail/customer-detail.module#CustomerDetailPageModule' },
  {
    path:'customerRequest',loadChildren:'./customer-request/customer-request.module#CustomerRequestPageModule'
  },
  { path: 'chat', loadChildren: './chat/chat.module#ChatPageModule' },
  { path: 'setting', loadChildren: './setting/setting.module#SettingPageModule' },
  { path: 'documentmanagement', loadChildren: './documentmanagement/documentmanagement.module#DocumentmanagementPageModule' },
  { path: 'drivinglicense', loadChildren: './drivinglicense/drivinglicense.module#DrivinglicensePageModule' },
  { path: 'edit-profile', loadChildren: './edit-profile/edit-profile.module#EditProfilePageModule' },
  { path: 'register', loadChildren: './register/register.module#RegisterPageModule' },
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'verify-otp', loadChildren: './verify-otp/verify-otp.module#VerifyOTPPageModule' },
  { path: 'slides', loadChildren: './slides/slides.module#SlidesPageModule' },
  { path: 'location', loadChildren: './location/location.module#LocationPageModule' },
  { path: 'terms-condictions', loadChildren: './terms-condictions/terms-condictions.module#TermsCondictionsPageModule' },
  { path: 'contact-us', loadChildren: './contact-us/contact-us.module#ContactUSPageModule' },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

